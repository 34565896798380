import React from "react"
import { Link, graphql, navigate } from "gatsby"

import Moment from 'react-moment';
import moment from 'moment';

import Calendar from 'rc-year-calendar';

import Layout from "../components/layout"


import { rhythm } from "../utils/typography"

/*
import LoginButton from "../components/auth/loginButton"
import LogoutButton from "../components/auth/logoutButton"
import Profile from "../components/auth/profile"
*/

//import { useAuth0 } from "@auth0/auth0-react";
import { withAuthenticationRequired } from '@auth0/auth0-react';


class BlogArchive extends React.Component {
  constructor(props){
    super(props);

    //Override set State method to update localStorage when changed
    const orginial = this.setState;
    if (typeof window !== 'undefined') {

      this.setState = function() {
          let arguments0 = arguments[0];
          let arguments1 = () => (arguments[1], localStorage.setItem('state', JSON.stringify(this.state)));
          orginial.bind(this)(arguments0, arguments1);
      };

      //Get state from localStorage if present
      this.state = JSON.parse(localStorage.getItem('state'))
          ? JSON.parse(localStorage.getItem('state'))
          : {
              showDay : false,
              posts : null,
              year  : 2019
            };

      this.hideDay = this.hideDay.bind(this);
      this.showDay = this.showDay.bind(this);
      this.onYearChanged = this.onYearChanged.bind(this);

      if('showDay' in props.location.state && !props.location.state.showDay){
        this.hideDay();
      }
    }
    else{
      this.state = {
              showDay : false,
              posts : null,
              year  : 2019
            }
    }

    this.data = this.build_data()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // If Route has changed, update state (Ensures consistency between location state and Component state).
    if (this.props.location !== prevProps.location) {
      this.setState(this.props.location.state);
    }
  }

  build_data(){
    const { data } = this.props
    const posts = data.allMysqlPost.edges

    //build date structure
    const dates = {};
    const dates_two = [];
    let min_date;
    let max_date;
    for(const post of posts){
      let y = moment(post.node.created).year();
      let m = moment(post.node.created).month();
      let d = moment(post.node.created).date();
      if( !( y in dates) ){
        dates[y] = new Array(12);
        for (let i = 0; i < dates[y].length; i++){
          dates[y][i] = new Array(moment([y, i]).daysInMonth());
        }
      }
      dates[y][m][d] = post.node.mysqlId;
      if (moment(post.node.created).isBefore(min_date)|| min_date == null){
        min_date = post.node.created;
      }
      if (moment(post.node.created).isAfter(max_date)|| max_date == null){
        max_date = post.node.created;
      }

      /*
      * Dates 2?
      */
      let da = {}
      da["startDate"] = moment(post.node.created).toDate();
      da["endDate"] = moment(post.node.created).toDate();
      da["name"] = "wot"
      da["post_id"] = post.node.mysqlId;
      da["content"] = post.node.content ? post.node.content.substring(0,50) : "";
      dates_two.push(da);

    }
    console.log(dates);
    console.log(min_date);
    console.log(max_date);

    return {
      min_date   : min_date,
      max_date   : max_date,
      datasource : dates_two
    };
  }

  onYearChanged(newyear){
    console.log(newyear);
    this.setState(state => ({
      year: newyear.currentYear
    }));
  }

  hideDay(){
    this.setState(state => ({
      showDay: false,
    }))
  }

  showDay(event){
    //console.log(date);
    if(event.events.length < 1){ return; }
    console.log(event);
    navigate('/archive', {showDay: false});
    navigate('/archive', {showDay: true, posts: event.events});
    this.setState(state => ({
      showDay: true,
      posts : event.events
    }));
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMysqlPost.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        { !this.state.showDay
          ? <Calendar style="background"
              dataSource={ this.data.datasource } 
              minDate={ moment(this.data.min_date).toDate() } 
              maxDate={ moment(this.data.max_date).toDate() }
              year={ this.state.year }
              defaultYear={ moment(this.data.max_date).year() }
              onDayClick={ this.showDay } 
              onYearChanged={ this.onYearChanged }/>
          : <div>
              <button onClick={ this.hideDay }>back</button>
              <h1 style={{
                  fontFamily: `Montserrat, sans-serif`,
                }}>
                <Moment format="LL">{posts[0].startDate}</Moment>
              </h1>
              { 
                this.state.posts.map(post => (
                  <div key={post.post_id}>
                    <h3
                      style={{
                        marginBottom: rhythm(1 / 4),
                      }}
                    >
                      <Link style={{ boxShadow: `none` }} to={"/" + post.post_id}>
                        <Moment format="LTS">
                          {post.startDate}
                        </Moment>
                      </Link>
                    </h3>
                    <small><Moment format="LLLL">{post.startDate}</Moment></small>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: ((post.content ? post.content + "...." : " " ))
                      }}
                    />
                  </div>
                ))
              }
            </div>
        }   

      </Layout>
    )
  }
}

export default withAuthenticationRequired(BlogArchive,{
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <div>Redirecting you to the login page...</div>,
})

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMysqlPost(sort: {fields: created, order: DESC}) {
      edges {
        node {
          id
          mysqlId
          content
          created
          created_utc
        }
      }
    }
  }
`